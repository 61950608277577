import React, { useRef } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import CardGeneral from './card-general'
import { Check } from 'react-feather'

import { useHiddenmsClassCity } from '../hooks/useHiddenmsClass'

const SidebarHeader = styled.h4`
  margin-bottom: 0.5em;
`
const SidebarP = styled.p`
  color: #444;
`
const TextPhoneStyle = styled.span``

const ContactInfo = () => {
  const footerRefs = useHiddenmsClassCity({
    handler: (ref) => {
      const className = ref.current.classList
      ref.current.className = className + ' hiddenms'
    },
  })
  const addressRef = useRef()
  const addressHeadingRef = useRef()
  footerRefs.push(addressRef)
  footerRefs.push(addressHeadingRef)
  return (
    <StaticQuery
      query={graphql`
        query ContactInfoQuery {
          site {
            siteMetadata {
              companyInfo {
                phone
                tollfreephone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
        }
      `}
      render={(data) => {
        let { siteMetadata } = data.site
        return (
          <div className='overflow-hidden rounded-xl shadow-md 0 bg-white'>
            <div class="flex items-center gap-x-4  px-6 py-4">
              <div class="text-brand-500 font-bold text-xl md:text-2xl font-display">About Us</div>

            </div>

            <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-base leading-6">
              {siteMetadata.companyInfo.license ? (

                <div class="flex justify-between gap-x-4 py-3">
                  <dt class="text-gray-500">Licensed and Insured</dt>
                  <dd class="text-gray-700">License #{siteMetadata.companyInfo.license}</dd>
                </div>
              ) : null}
              {siteMetadata.companyInfo.phone ? (

                <div class="flex justify-between gap-x-4 py-3">
                  <dt class="text-gray-500">Phone</dt>
                  <dd class="text-gray-700">{siteMetadata.companyInfo.phone}</dd>
                </div>
              ) : null}
              {siteMetadata.companyInfo.email ? (

                <div class="flex justify-between gap-x-4 py-3">
                  <dt class="text-gray-500">Email</dt>
                  <dd class="text-gray-700">{siteMetadata.companyInfo.email}</dd>
                </div>
              ) : null}


              <div class="flex justify-between gap-x-4 py-3 text-right">
                <dt class="text-gray-500">Location</dt>
                <dd class="text-gray-700">101 Virginia Ln, Nicholasville, KY 40356, USA</dd>
              </div>



            </dl>
          </div>
        )
      }}
    />
  )
}
export default ContactInfo
